import { Grid } from "@mui/material";
import React from "react";

export default function Homes() {
  return (
    <>
      <section className="main-img"></section>
      <section className="section-white pa-1 ">
        <div className="container-kws">
          <div className="m-t-1 m-b-1">
            <h2 className="t-a-c f-fam c-b f-w-600">ABOUT US</h2>
            <div>
              <h4 className="m-t-3 f-w-800 c-b-s-h f-fam">
                ENEN Associates is a Hyderabad based Interior turnkey
                contracting company.
              </h4>
              <p className="f-fam c-g f-w-600 m-t-1">
                We are one of the most admired and leading firms in the Interior
                contracting industry. Our unique approach of delivering quality
                work and bringing ideas to reality has helped ENEN Associates
                make quantifiable value delivery in Projects. Our team comprises
                qualified site engineers, trained carpenters, specialized skill
                set personnel with expertise and work style that represent us.
                ENEN is constantly evolving as an organisation. ENEN has worked
                with the best of architects across Banglore and large
                corporations (Indian and multinational) over the last few years,
                and we have built a reputation of delivering excellence in
                quality within the stipulated time. It is our commitment to
                provide premium quality output by channelizing our creativity
                and bringing innovative ideas to the fore.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-black">
        <div className="container-kws">
          <div className="m-t-2 m-b-2">
            <h2 className="t-a-c f-fam c-w f-w-600">OUR VISION & CREATIVITY</h2>
            <Grid container className="m-t-4">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="f-fam m-r-1 c-w">
                  ENEN Associates is on the way to becoming the most preferred
                  service provider throughout India for a complete range of
                  Interior Designing & Building Solutions and we are constantly
                  striving to ahieve its goal towards sustainable construction
                  by following the principles of “CONSERVE, REUSE, RECYCLE,
                  PROTECT NATURE, USE NON-TOXIC & HIGH QUALITY PRODUCTS”.
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="f-fam m-l-1 vision c-w">
                  A CREATIVE IDEA IS THE LADDER TO SUCCESS. Capital & Experience
                  are not the toughest things to get in the industry. With
                  efforts, both can be acquired. What is the most important
                  factor? It is a creative IDEA! The idea is the core asset you
                  need to take your business to its fullest potential. With our
                  extremely experienced team and skills, we are always in search
                  of innovative ideas and designs, and that is what our clients
                  push us for.
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="section-white">
        <div className="container-kws">
          <div className="p-t-2 p-b-2">
            {" "}
            <h2 className="t-a-c f-fam  c-b f-w-600">OUR TECHNICAL TEAM</h2>
            <Grid container className="m-t-4">
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <div>
                  <h5 className="f-fam c-b f-w-600">Y. V. NARAYANA REDDY</h5>
                  <p className="c-b f-w-500 f-fam">M. Tech (Civil)</p>
                  <p className="c-b f-w-500 f-fam">30 Years of Experience</p>
                  <p className="c-b f-w-600 f-fam">
                    Expertise:{" "}
                    <span className="c-b f-w-500 f-fam">
                      Civil Works & Structural Drawings
                    </span>
                  </p>
                  <p className="c-b f-w-600 f-fam">
                    Location:{" "}
                    <span className="c-b f-w-500 f-fam">Hyderabad</span>
                  </p>
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <div>
                  <h5 className="f-fam c-b f-w-600">P. V. NARENDRA BABU</h5>
                  <p className="c-b f-w-500 f-fam">M. Com</p>
                  <p className="c-b f-w-500 f-fam">15 Years of Experience</p>
                  <p className="c-b f-w-600 f-fam">
                    Expertise:{" "}
                    <span className="c-b f-w-500 f-fam">
                      Administration & Finance
                    </span>
                  </p>
                  <p className="c-b f-w-600 f-fam">
                    Location:{" "}
                    <span className="c-b f-w-500 f-fam">
                      Hyderabad & Bengaluru
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <div className="techinical-section">
                  <h5 className="f-fam c-b f-w-600">B. JAGADESH</h5>
                  <p className="c-b f-w-500 f-fam">B. Arch.</p>
                  <p className="c-b f-w-500 f-fam">15 Years of Experience</p>
                  <p className="c-b f-w-600 f-fam">
                    Expertise:{" "}
                    <span className="c-b f-w-500 f-fam">
                      2D & 3D Drawings & Interior Designing
                    </span>
                  </p>
                  <p className="c-b f-w-600 f-fam">
                    Location:{" "}
                    <span className="c-b f-w-500 f-fam">Bengaluru</span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="section-grey">
        <div className="container-kws">
          <div className="p-t-2 p-b-2">
            <h2 className="t-a-c m-b-3 f-fam c-b-s-h f-w-600">NUMBERS TELL A STORY</h2>
            <Grid container>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <div className="box m-r-1 p-t-3 p-b-3">
                  <h1 className="f-fam c-b-b f-w-800 t-a-c">1322500</h1>
                  <p className=" f-s-1_5 f-fam c-b f-w-600 t-a-c">
                    SQFT AREA DESIGNED
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <div className="box m-r-1 p-t-3 p-b-3">
                  <h1 className="f-fam c-b-b f-w-800 t-a-c">28</h1>
                  <p className=" f-s-1_5 f-fam c-b f-w-600 t-a-c">
                    SKILLED TEAM MEMBERS
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <div className="box p-t-3 p-b-3">
                  <h1 className="f-fam c-b-b f-w-800 t-a-c">35+</h1>
                  <p className=" f-s-1_5 f-fam c-b f-w-600 t-a-c">
                    PROJECTS DELIVERED
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="section-dark-grey">
        <div className="container-kws">
          <div className="p-t-2 p-b-2 our-service">
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={4} >
                <h3 className="f-fam f-c-l-w servi-border">OUR SERVICES</h3>
                <h6 className="f-fam f-c-l-w m-t-3 m-b-3">INTERIOR FIT-OUT TURNKEY</h6>
                <p className="f-fam f-c-l-w">
                  Our team has a vast expertise in managing and value adding to
                  every stage of the Project management cycle, which includes
                </p>
                <p className="f-fam f-c-l-w">1. Development consulting.</p>
                <p className="f-fam f-c-l-w">2. Design development.</p>
                <p className="f-fam f-c-l-w">3. Procurement management.</p>
                <p className="f-fam f-c-l-w">4 .Construction management.</p>
                <p className="f-fam f-c-l-w">
                  5. Cost consultancy and project monitoring.
                </p>
                <p className="f-fam f-c-l-w">
                  We provide services across Interior fit-out refurbishment and
                  built-to-suit projects.
                </p>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className="p-t-2">
                <h6 className="f-fam m-t-3 f-c-l-w m-b-3">CIVIL & INTERIOR</h6>

                <p className="f-fam f-c-l-w">1. Interior</p>
                <p className="f-fam f-c-l-w">2. Project Implementation & Management</p>
                <p className="f-fam f-c-l-w">3. Procurement & Value Engineering</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}><div className="p-t-2">
              <h6 className="f-fam m-t-3 f-c-l-w m-b-3">MEP SERVICES</h6>

              <p className="f-fam f-c-l-w">1. HVAC</p>
              <p className="f-fam f-c-l-w">2. Electrical</p>
              <p className="f-fam f-c-l-w">3. BMS</p>
              </div></Grid>
            </Grid>
          </div>
        </div>
      </section>
    </>
  );
}
